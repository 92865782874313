class LockJobInfo
{
    lockId;
    lastJobDate;
    lastSuccessJobDate;

    constructor(data: Object|null = null) {
        if(data !== undefined && data !== null) {
           
            this.lockId       = data.lockId;
            this.lastJobDate     = data.lastJobDate ? new Date(data?.lastJobDate).toLocaleString() : null;
            this.lastSuccessJobDate   = data.lastSuccessJobDate ? new Date(data?.lastSuccessJobDate).toLocaleString() : null;
        }
    }
}

export default LockJobInfo;
import UserHasAccessInfo from './Customer/UserHasAccessInfo';
import LockJobInfo  from './Customer/LockJobInfo';

class Customer {
    /** @type {string} */
    location;
    /** @type {string} */
    unit;
    /** @type {int} */
    user;
    /** @type {string} */
    surname;
    /** @type {string} */
    name;
    /** @type {string} */
    email;
    /** @type {string} */
    llmCode;
    /** @type {string} */
    satelCode;
    /** @type {string} */
    userId;
    /** @type {boolean} */
    userHasAccess;
    /** @type {UserHasAccessInfo} */
    userHasAccessInfo;
    /** @type {int} */
    userBankStatus;
    /** @type {boolean} */
    manualLock;
    /** @type {LockJobInfo} */
    lockJobInfo;

    constructor(data: Object|null = null) {
        if(data !== undefined && data !== null) {
            this.location       = data.location;
            this.unit           = data.unit;
            this.user           = data.user;
            this.surname        = data.surname;
            this.name           = data.name;
            this.email          = data.email;
            this.llmCode        = data.llmCode;
            this.satelCode      = data.satelCode;
            this.userId         = data.userId;
            this.userHasAccess  = data.userHasAccess;
            this.userHasAccessInfo = new UserHasAccessInfo(data.userHasAccessInfo);
            this.userBankStatus = data.userBankStatus;
            this.manualLock     = data.manualLock;
            this.lockJobInfo    = new LockJobInfo(data.lockJobInfo);
        }

        else {
            this.location       = '';
            this.unit           = '';
            this.user           = 0;
            this.surname        = '';
            this.name           = '';
            this.email          = '';
            this.llmCode        = '';
            this.satelCode      = '';
            this.userHasAccess  = '';
            this.userHasAccessInfo = new UserHasAccessInfo();
            this.userId         = '';
            this.userBankStatus = 404;
            this.manualLock     = false;
            this.lockJobInfo    = new LockJobInfo();
        }
    }

    // toJson() {
    //     const userData = {
    //         UserEmail: this.email || '',
    //         UserFirstName: this.firstName || '',
    //         UserLastName: this.lastName || '',
    //         UserPasswordHash: this.passwordHash || '',
    //         ClientId: this.clientId || ''
    //     };
    //
    //     if(this.id.trim() !== '') { userData['UserId'] = this.id; }
    //
    //     return userData;
    // }
}

export default Customer;
import * as React from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { useIntl } from 'react-intl';
import { DialogActions } from '@mui/material';

export interface ConfirmDialogProps {
  open: boolean;
  title: string;
  onNo: () => void;
  onYes: () => void;
}

export default function ConfirmDialog(props: ConfirmDialogProps) {

  const intl = useIntl();

  const { onNo, onYes , title, open } = props;

  return (
    <Dialog open={open}>
       
      <DialogTitle>{title}</DialogTitle>
      
      <DialogActions>
            <Button variant="contained" onClick={onYes}>{intl.formatMessage({       
                                      id: "bool_true",
                                    })}</Button>
            <Button variant="outlined" onClick={onNo}>{intl.formatMessage({       
                                      id: "bool_false",
                                    })}</Button>     
        </DialogActions>                        
    </Dialog>
  );
}
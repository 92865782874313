import React, { useCallback } from "react";
import { useEffect, useState } from "react";
import { AuthenticatedLayout } from "../../layouts/AuthenticatedLayout";
import { FormattedMessage, useIntl } from "react-intl";
import Customer from "../../backend/DTO/Customer";
import {
  changeCustomerManualLock,
  getCustomers,
  getCustomersSmall,
  exportCustomers,
  getLocations,
  laerAccessUser,
  laerAccessUpdate,
  setParentUser,
  // getParentUser,
  userBankRatingHistory,
  userBankRatingUpdate,
  getFileUploads,
  downloadFile,
  resetLockJob,
  resetUserPasword
} from "../../backend";
import Search from "../../components/Search";
import {
  DangerButton,
  LightButton,
  PrimaryButton,
  SecondaryButton,
} from "../../components/Buttons";
import { MultiSelect } from "react-multi-select-component";
import Modal from "../../components/Modal";
import SelectSearch from "react-select-search";
import { useMsal } from "@azure/msal-react";
import UserBankRating from "../../backend/DTO/UserBankRating";
import DropdownList from "react-widgets/DropdownList";
import { Tooltip } from "react-tooltip";
import { renderToString } from "react-dom/server";
import { BsCopy } from "react-icons/bs";
import { GrPowerReset } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import { MdOutlineLockReset } from "react-icons/md";
import { NotificationManager } from "react-notifications";
import ConfirmDialog from "../../components/ConfirmDialog";
import CircularProgress from '@mui/material/CircularProgress';
import { Alert, Backdrop, Snackbar } from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';

function List() {

  const navigate = useNavigate();
  const { accounts } = useMsal();
  const performedUserName =
    (accounts[0] && accounts[0].name) || /*'Sivov Anton'*/ null;
  const performedUserEmail =
    (accounts[0] && accounts[0].username) || /*'Anton.Sivov@inbox.ru'*/ null;

  const [loading, setLoading] = useState(true);
  const [loadingAdditional, setLoadingAdditional] = useState(false);

  const [searchQuery, setSearchQuery] = useState(null);
  const [rawSearchQuery, setRawSearchQuery] = useState(null);
  const [locationsFilter, setLocationsFilter] = useState([]);

  const [customers, setCustomers] = useState([]);

  const [locationOptions, setLocationOptions] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const [filterLocations, setFilterLocations] = useState([]);

  const [orderField, setOrderField] = useState(0);
  const [orderType, setOrderType] = useState(0);

  const [exportLoading, setExportLoading] = useState(false);

  const [fileUploads, setFileUploads] = useState([]);

  const [showEmptyFilter, setShowEmptyFilter] = useState(false);
  const [showLockedFilter, setShowLockedFilter] = useState(false);

  const [loaderVisible, setLoaderVisible] = useState(false);
  const [isSuccessAlertVisible, setIsSuccessAlertVisible] = useState(false);
  const [isErrorAlertVisible, setIsErrorAlertVisible] = useState(false);

  const [isResetPasswordDialgoVisible, setIsResetPasswordDialgoVisible] = useState(false)
  const [resetPasswordUserId, setResetPasswordUserId] = useState("")

  const [isResetJobDialgoVisible, setIsResetJobDialgoVisible] = useState(false)
  const [resetJobId, setResetJobId] = useState("")

  const loadData = useCallback(
    (
      more = false,
      searchQuery = null,
      locationsFilter = null,
      showEmptyFilter: boolean = false,
      showLockedFilter: boolean = false,
      orderField: Number = 0,
      orderType: Number = 0
    ) => {
      if (more) {
        setLoadingAdditional(true);
      } else {
        setLoading(true);
      }
      getCustomers(
        searchQuery,
        locationsFilter,
        showEmptyFilter,
        showLockedFilter,
        more ? customers.length : 0,
        15,
        orderField,
        orderType
      )
        .then((customersResponse) => {
          setCustomers(
            more ? customers.concat(customersResponse) : customersResponse
          );
        })
        .finally(() => {
          setLoading(false);
          setLoadingAdditional(false);
        });
    },
    [customers]
  );

  const exportData = () => {
    setExportLoading(true);
    exportCustomers(
      rawSearchQuery,
      filterLocations.map((el) => el.value),
      orderField,
      orderType
    )
      .then(async (res) => {
        if (res.ok) {
          let filename = "export.xlsx";
          if (res.headers.has("Content-Disposition")) {
            const dispositionParts = res.headers
              .get("Content-Disposition")
              .split(";");

            for (const key in dispositionParts) {
              if (dispositionParts[key].trim().startsWith("filename=")) {
                filename = dispositionParts[key].trim().split("filename=")[1];
                break;
              }
            }
          }

          return { blob: await res.blob(), filename: filename };
        } else return {};
      })
      .then(({ blob, filename }) => {
        if (blob && filename) {
          const anchorElement = document.createElement("a");
          document.body.appendChild(anchorElement);

          anchorElement.style.display = "none";

          const url = window.URL.createObjectURL(blob);

          anchorElement.href = url;
          anchorElement.download = filename;
          anchorElement.click();

          window.URL.revokeObjectURL(url);
        }
      })
      .finally(() => {
        setExportLoading(false);
      });
  };

  useEffect(() => {
    getLocations().then((locations) => {
      setLocationOptions(locations);
    });
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const search = (searchQuery) => {
    setSearchQuery(searchQuery);
    loadData(false, searchQuery, locationsFilter, showEmptyFilter, showLockedFilter);
  };

  const applyFilters = (locations) => {
    setLocationsFilter(locations);
    loadData(false, searchQuery, locations, showEmptyFilter, showLockedFilter);
  };

  const changeOrder = (fieldForOrder: Number) => {
    let typeOrder =
      orderField === fieldForOrder ? Number(!Boolean(orderType)) : 0;

    setOrderField(fieldForOrder);
    setOrderType(typeOrder);

    loadData(false, searchQuery, locationsFilter, showEmptyFilter, showLockedFilter, orderField, orderType);
  };

  const printOrderForField = (field: Number) => {
    if (field !== orderField) {
      return null;
    }

    if (orderType === 0) {
      return <i className="fa fa-arrow-up"></i>;
    } else {
      return <i className="fa fa-arrow-down"></i>;
    }
  };

  const [
    selectedForChangeManualLockCustomer,
    setSelectedForChangeManualLockCustomer,
  ] = useState(null);

  const onCloseChangeManualLockModal = () => {
    setSelectedForChangeManualLockCustomer(null);
  };
  const changeManualLockAction = () => {
    if (selectedForChangeManualLockCustomer !== null) {
      changeCustomerManualLock(selectedForChangeManualLockCustomer).then(() => {
        loadData(false, searchQuery, locationsFilter, orderField, orderType);
        onCloseChangeManualLockModal();
      });
    }
  };

  const [selectedForChangeCustomerParent, setSelectedForChangeCustomerParent] =
    useState(null);
  const [selectedCustomerType, setSelectedCustomerType] = useState(null);

  const [
    selectedCustomerParentSearchValue,
    setSelectedCustomerParentSearchValue,
  ] = useState(null);
  const [changeParentModalShowError, setChangeParentModalShowError] =
    useState(false);

  const getSearchParentOptions = (query) => {
    return new Promise((resolve, reject) => {
      if (query.length < 2) {
        resolve([]);
      } else {
        getCustomersSmall(query)
          .then((data) => {
            const options = data.map((el) => {
              return {
                name: `${el.surname} ${el.name} (${el.email})`,
                value: el.userId,
              };
            });
            resolve(options);
          })
          .catch((err) => reject(err));
      }
    });
  };

  // const openChangeParentModal = (customer: Customer) => {
  //   setChangeParentModalShowError(false);
  //   setSelectedCustomerType(customer.user);
  //   if (customer.user === 1) {
  //     getParentUser(customer).then((val) => {
  //       setSelectedCustomerParentSearchValue({
  //         name: `${val.surname} ${val.name} (${val.email})`,
  //         value: val.userId,
  //       });
  //     });
  //   }
  //   setSelectedForChangeCustomerParent(customer);
  // };

  const onCloseChangeParentModal = () => {
    setSelectedForChangeCustomerParent(null);
    setSelectedCustomerType(null);
    setSelectedCustomerParentSearchValue(null);
    setParentSearchError(false);
  };

  const setParentSearchError = (error: boolean = false) => {
    setChangeParentModalShowError(error);

    const parentSearchBlock = document.getElementById("parentSearch");
    const inputBlock = parentSearchBlock.querySelector(".select-search-input");

    if (error) {
      inputBlock.classList.add("border-danger");
    } else {
      inputBlock.classList.remove("border-danger");
    }
  };

  const changeParentAction = () => {
    if (selectedForChangeCustomerParent !== null) {
      if (
        selectedCustomerType === 1 &&
        !selectedCustomerParentSearchValue?.value
      ) {
        setParentSearchError(true);
      } else {
        setParentUser(
          selectedForChangeCustomerParent,
          selectedCustomerType === 0
            ? null
            : selectedCustomerParentSearchValue?.value
        )
          .then(() => {
            loadData(
              false,
              searchQuery,
              locationsFilter,
              orderField,
              orderType
            );
            onCloseChangeParentModal();
          })
          .catch((err) => {
            setParentSearchError(true);
          });
      }
    }
  };

  const [selectedLaerAccess, setSelectedForLaerAceess] = useState(null);
  const [laerAceessLoading, setLaerAccessLoading] = useState(false);
  const [laerAccessData, setLaerAccessData] = useState(null);
  const [accessCodes, setAccessCodes] = useState([]);
  const [satelCode, setSatelCode] = useState(null);

  const openEditLaerAccessModal = (customer: Customer) => {
    setLaerAccessLoading(true);
    setSelectedForLaerAceess(customer);
    laerAccessUser(customer.userId)
      .then((response) => {
        setAccessCodes(response.accessCodes ? response.accessCodes : []);
        setSatelCode(response.satelCode ? response.satelCode : "");
        setLaerAccessData(response);
      })
      .finally(() => {
        setLaerAccessLoading(false);
      });
  };
  const closeEditLaerAccessModal = () => {
    setSelectedForLaerAceess(null);
    setLaerAccessData(null);
  };

  const addLaerAccessCode = () => {
    setLaerAccessLoading(true);
    let currentData = [...accessCodes];
    currentData.push({ code: "" });
    setAccessCodes(currentData);
    setLaerAccessLoading(false);
  };

  const removeLaerAccessCode = (index) => {
    setLaerAccessLoading(true);
    let currentData = [...accessCodes];
    currentData.splice(index, 1);
    setAccessCodes(currentData);
    setLaerAccessLoading(false);
  };

  const changeLaerAccessCode = (index, value) => {
    let currentData = [...accessCodes];
    if (currentData[index]) {
      currentData[index].code = value;
    }
    setAccessCodes(currentData);
  };

  const editLLMAction = () => {
    if (selectedLaerAccess !== null) {
      laerAccessUpdate(
        selectedLaerAccess.userId,
        satelCode,
        accessCodes.map((el) => el.code)
      ).then(() => {
        loadData(false, searchQuery, locationsFilter, orderField, orderType);
        closeEditLaerAccessModal();
      });
    }
  };

  const getBankStatusColorClass = (bankStatus: number) => {
    if (bankStatus < 0) return "bg-secondary-light";
    if (bankStatus <= 1) return "bg-success";
    if (bankStatus >= 4) return "bg-danger";

    return "bg-warning";
  };

  const [bankRatingModalRef, setBankRatingModalRef] = useState(null);
  const [editBankRating, setEditBankRating] = useState(null);
  const [loadingBankRatingHistory, setLoadingBankRatingHistory] =
    useState(false);
  const [bankRatingHistory, setBankRatingHistory] = useState([]);

  const openBankRatingModal = (customer: Customer) => {
    setEditBankRating(customer.userBankStatus);
    setBankRatingModalRef(customer);

    setLoadingBankRatingHistory(true);
    userBankRatingHistory(customer.userId)
      .then((data) => {
        setBankRatingHistory(data.sort((a, b) => b.updateDate - a.updateDate));
      })
      .finally(() => {
        setLoadingBankRatingHistory(false);
      });
  };

  const closeBankRatingModal = () => {
    setBankRatingModalRef(null);
    setEditBankRating(null);
    setBankRatingHistory([]);
    setLoadingBankRatingHistory(false);
  };

  const [
    bankRatingUpdateConfirmationModalRef,
    setBankRatingUpdateConfirmationModalRef,
  ] = useState(null);
  const openBankRatingUpdateConfirmationModal = (customer: Customer) => {
    setBankRatingUpdateConfirmationModalRef(customer);
  };
  const closeBankRatingUpdateConfirmationModal = () => {
    setBankRatingUpdateConfirmationModalRef(null);
  };

  const updateBankRating = () => {
    userBankRatingUpdate(
      bankRatingModalRef.userId,
      editBankRating,
      performedUserName,
      performedUserEmail
    ).then(() => {
      loadData(false, searchQuery, locationsFilter, orderField, orderType);
      closeBankRatingModal();
      closeBankRatingUpdateConfirmationModal();
    });
  };

  const [fileUploadsSeek, setFileUploadsSeek] = useState(0);
  const fileUploadsTake = 10;

  const [showMoreFileUploads, setShowMoreFileUploads] = useState(true);
  const [isFileUploadLoading, setIsFileUploadLoading] = useState(false);
  const [store365ModalRef, setStore365ModalRef] = useState(null);

  const loadFileUploads = () => {
    setIsFileUploadLoading(true);
    getFileUploads(fileUploadsSeek, fileUploadsTake).then((data) => {
      setShowMoreFileUploads(data.length >= fileUploadsTake);
      var uploads = fileUploads.concat(data);
      setFileUploads(uploads);
      setFileUploadsSeek(fileUploadsSeek + 10);
      setIsFileUploadLoading(false);
    });
  };

  const openStore365ModalRefModal = () => {
    setFileUploadsSeek(0);
    setStore365ModalRef(123);
    loadFileUploads();
  };

  const closeStore365ModalRefModal = () => {
    setFileUploads([]);
    setFileUploadsSeek(0);
    setStore365ModalRef(null);
  };

  const intl = useIntl();

  useEffect(() => {
    document.title = "Laer Gruppe";
    console.log(<div />);
  }, []);

  return (
    <AuthenticatedLayout>
     
      <Snackbar open={isSuccessAlertVisible} autoHideDuration={3000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
          Done!
         </Alert>
      </Snackbar>

      <Snackbar open={isErrorAlertVisible} autoHideDuration={3000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert severity="error">
           Fail!
        </Alert>
      </Snackbar>
      
      <div className="input-group-project-st background-none">
        <PrimaryButton
          className={"margin-left-auto"}
          onClick={() => {
            openStore365ModalRefModal();
          }}
        >
          Store 365
        </PrimaryButton>
      </div>
      <Search onSearch={search} onChange={setRawSearchQuery}>
        <PrimaryButton
          className={exportLoading ? "disabled" : ""}
          onClick={exportData}
        >
          {exportLoading ? (
            <div className="spinner-border" role="status">
              <span className="visually-hidden">
                <FormattedMessage id="loading" />
              </span>
            </div>
          ) : (
            <FormattedMessage id={"export_button"} />
          )}
        </PrimaryButton>
      </Search>

      <div
        className="col-12 flex-wrap flex-column"
        style={{ marginTop: 10 + "px", marginBottom: 10 + "px" }}
      >
        <PrimaryButton onClick={() => setShowFilters(!showFilters)}>
          <FormattedMessage id={"filters_button"} />
        </PrimaryButton>

        {
          showEmptyFilter ?
            <PrimaryButton onClick={() => {
              setShowEmptyFilter(false);
              loadData(false, searchQuery, locationsFilter, false, showLockedFilter, orderField, orderType);
            }} className="active ml-10 bg-danger">
              <FormattedMessage id={"filter_button_show_empty"} />
            </PrimaryButton>
            :
            <SecondaryButton onClick={() => {
              setShowEmptyFilter(true);
              loadData(false, searchQuery, locationsFilter, true, showLockedFilter, orderField, orderType);
            }} className="ml-10">
              <FormattedMessage id={"filter_button_show_empty"} />
            </SecondaryButton>
        }
        {
          showLockedFilter ?
            <PrimaryButton onClick={() => {
              setShowLockedFilter(false);
              loadData(false, searchQuery, locationsFilter, showEmptyFilter, false, orderField, orderType);
            }} className="active ml-10 bg-danger">
              <FormattedMessage id={"filter_button_show_locked"} />
            </PrimaryButton>
            :
            <SecondaryButton onClick={() => {
              setShowLockedFilter(true);
              loadData(false, searchQuery, locationsFilter, showEmptyFilter, true, orderField, orderType);
            }} className="ml-10">
              <FormattedMessage id={"filter_button_show_locked"} />
            </SecondaryButton>
        }

        <div className={"bg-white mt-2 p-4 " + (showFilters ? "" : "d-none")}>
          <div className="mb-3">
            <label className="form-label">
              <FormattedMessage id="field_location" />
            </label>
            <MultiSelect
              options={locationOptions.map((location) => {
                return { label: location, value: location };
              })}
              value={filterLocations}
              onChange={setFilterLocations}
              labelledBy="Select"
            />
          </div>

          <div
            className={"col-12 d-flex justify-content-end align-content-center"}
          >
            <PrimaryButton
              onClick={() => {
                applyFilters(filterLocations.map((el) => el.value));
              }}
            >
              <FormattedMessage id={"apply_filters"} />
            </PrimaryButton>
          </div>
        </div>
      </div>
      <div className="table-box mt-8">
        {loading ? (
          <div className="align-content-center text-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">
                <FormattedMessage id="loading" />
              </span>
            </div>
          </div>
        ) : (
          <>
            <div className="table-content">
              <table className="table">
                <thead>
                  <tr className="border-top-1 border-bottom-1">
                    <th
                      onClick={() => {
                        changeOrder(0);
                      }}
                      className="text-nowrap align-middle cursor-pointer hover-text-decoration-underline"
                    >
                      {printOrderForField(0)}
                      <FormattedMessage id="field_location" />
                    </th>
                    <th                      
                      onClick={() => {
                        changeOrder(1);
                      }}
                      className="text-nowrap align-middle cursor-pointer hover-text-decoration-underline"
                    >
                      {printOrderForField(1)}
                      <FormattedMessage id="field_unit" />                    
                    </th>
                    <th
                      style={{width:'150px'}}
                      onClick={() => {
                        changeOrder(2);
                      }}
                      
                      className="text-nowrap align-middle cursor-pointer hover-text-decoration-underline"
                    >
                      {printOrderForField(2)}
                      <FormattedMessage id="field_user" />
                    </th>
                    <th className="text-nowrap align-middle">
                      <div className="d-inline-block text-nowrap">
                        <div
                          onClick={() => {
                            changeOrder(3);
                          }}
                          className="d-inline-block text-nowrap cursor-pointer hover-text-decoration-underline"
                        >
                          {printOrderForField(3)}
                          <FormattedMessage id="field_last_name" />
                        </div>

                        <div
                          onClick={() => {
                            changeOrder(4);
                          }}
                          className="ml-10 d-inline-block text-nowrap cursor-pointer hover-text-decoration-underline"
                        >
                          {printOrderForField(4)}
                          <FormattedMessage id="field_first_name" />
                        </div>
                      </div>

                      <br />

                      <div
                        onClick={() => {
                          changeOrder(5);
                        }}
                        className="d-inline-block text-nowrap cursor-pointer hover-text-decoration-underline"
                      >
                        {printOrderForField(5)}
                        <FormattedMessage id="field_email" />
                      </div>
                    </th>
                    <th
                      onClick={() => {
                        changeOrder(6);
                      }}
                      className="text-nowrap align-middle cursor-pointer hover-text-decoration-underline"
                    >
                      {printOrderForField(6)}
                      <FormattedMessage id="field_llm_code" />
                    </th>
                    <th
                      onClick={() => {
                        changeOrder(7);
                      }}
                      className="text-nowrap align-middle cursor-pointer hover-text-decoration-underline"
                    >
                      {printOrderForField(7)}
                      <FormattedMessage id="field_satel_code" />
                    </th>
                    <th className="text-nowrap align-middle">
                      <FormattedMessage id="field_bank_status" />
                    </th>

                    <th
                      onClick={() => {
                        changeOrder(8);
                      }}
                      className="text-nowrap align-middle cursor-pointer hover-text-decoration-underline"
                    >
                      {printOrderForField(8)}
                      <FormattedMessage id="field_user_has_access" />
                    </th>

                    <th className="text-nowrap align-middle">
                      <FormattedMessage id="field_manual_lock" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {customers.length > 0 ? (
                    customers.map((item: Customer, index) => (
                      <>
                        <tr className="border-top-1 border-bottom-1">
                          <td className="align-middle">
                            {item?.location || ""}
                          </td>
                          <td className="align-middle text-nowrap">
                          <GrPowerReset
                              data-tooltip-id="job-reset-tooltip"
                              data-tooltip-html={"reset"}
                              style={{
                                marginRight: "5px",
                                marginBottom: "3px",
                                cursor:"pointer"
                              }}
                              onClick={() => {
                                setResetJobId(item.lockJobInfo.lockId);
                                setIsResetJobDialgoVisible(true);
                              }}
                            >
                             
                            </GrPowerReset>
                          <span
                              className="cursor-pointer"
                              data-tooltip-id="job-update-tooltip"
                              data-tooltip-html={renderToString(
                                <div className="p-2">
                                  <b>
                                    {intl.formatMessage({
                                      id: "last_update_date",
                                    })}
                                  </b>
                                  : {
                                      (item?.lockJobInfo?.lastJobDate ?? "-" )+ (item?.lockJobInfo?.lastJobDate === item?.lockJobInfo?.lastSuccessJobDate ? " ✅" : "")
                                    }
                                  <br />
                                  <b>
                                    {intl.formatMessage({
                                      id: "last_success_update_date",
                                    })}
                                  </b>

                                  : {item?.lockJobInfo?.lastSuccessJobDate ?? "-"}
                                </div>
                              )}
                            >
                              {item.unit || ""}
                            </span>
                            
                            
                          </td>
                          <td
                            className="align-middle cursor-pointer "
                          >
                            <MdOutlineLockReset
                              data-tooltip-id="user-reset-password-tooltip"
                              data-tooltip-html={"reset password"}
                              style={{
                                marginRight: "5px",
                                marginBottom: "3px",
                                cursor:"pointer"
                              }}
                              onClick={() => {
                                setResetPasswordUserId(item.userId);
                                setIsResetPasswordDialgoVisible(true);                                                          
                              }}
                            >                             
                            </MdOutlineLockReset>
                            <span className="hover-text-decoration-underline"  onClick={() => navigate("/users") }>
                            <FormattedMessage id={
                                item?.user === 0
                                  ? "customers_user_main"
                                  : "customers_user_sub"
                              }
                            />
                            </span>
                            
                          </td>
                          <td className="align-middle">
                            <div className="d-inline-block text-nowrap">
                              {item?.surname || ""} {item?.name || ""}
                            </div>

                            <br />

                            <div className="d-inline-block text-nowrap">
                              {item?.email || ""}
                            </div>
                          </td>
                          <td
                            className="align-middle cursor-pointer hover-text-decoration-underline"
                            onClick={() => openEditLaerAccessModal(item)}
                          >
                            {item?.llmCode || ""}
                          </td>
                          <td
                            className="align-middle cursor-pointer hover-text-decoration-underline"
                            onClick={() => openEditLaerAccessModal(item)}
                          >
                            {item?.satelCode
                              ? item?.satelCode.length > 12
                                ? item?.satelCode.substring(0, 12)
                                : item?.satelCode
                              : ""}
                          </td>
                          <td className="align-middle text-center">
                            <div
                              onClick={() => openBankRatingModal(item)}
                              style={{
                                height: "40px",
                                width: "40px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                              className={
                                "cursor-pointer border rounded text-white " +
                                getBankStatusColorClass(item.userBankStatus)
                              }
                            >
                              {item?.userBankStatus >= 0
                                ? "0" + item?.userBankStatus
                                : item?.userBankStatus}
                            </div>
                          </td>
                          <td className="align-middle">
                            <span
                              className="cursor-pointer"
                              data-tooltip-id="user-has-access-info-tooltip"
                              data-tooltip-html={renderToString(
                                <div className="p-2">
                                  <b>
                                    {intl.formatMessage({
                                      id: "field_lock_status",
                                    })}
                                  </b>
                                  : {item?.userHasAccessInfo.lockStatusText}{" "}
                                  {item?.userHasAccessInfo.lockStatusOk
                                    ? "✅"
                                    : "🚫"}
                                  <br />
                                  <b>
                                    {intl.formatMessage({
                                      id: "field_bank_status",
                                    })}
                                  </b>
                                  : {item?.userHasAccessInfo.userBankStatusText}{" "}
                                  {item?.userHasAccessInfo.userBankStatusOk
                                    ? "✅"
                                    : "🚫"}
                                  <br />
                                  <b>
                                    {intl.formatMessage({
                                      id: "field_manual_lock",
                                    })}
                                  </b>
                                  :{" "}
                                  {item?.userHasAccessInfo.manualOk
                                    ? "✅"
                                    : "🚫"}
                                </div>
                              )}
                            >
                              <FormattedMessage
                                id={
                                  item?.userHasAccess
                                    ? "bool_true"
                                    : "bool_false"
                                }
                              />
                            </span>
                          </td>
                          <td className="align-middle text-center">
                            <input
                              type="checkbox"
                              className="cursor-pointer"
                              checked={item?.manualLock || false}
                              onChange={() =>
                                setSelectedForChangeManualLockCustomer(item)
                              }
                            />
                          </td>
                        </tr>
                      </>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={11} className="text-center">
                        <FormattedMessage id={"no_results"} />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            {loadingAdditional ? (
              <div className="align-content-center text-center">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">
                    <FormattedMessage id="loading" />
                  </span>
                </div>
              </div>
            ) : null}

            <div className="text-center mt-40">
              <PrimaryButton
                onClick={() => {
                  loadData(
                    true,
                    searchQuery,
                    locationsFilter,
                    orderField,
                    orderType
                  );
                }}
                disabled={loadingAdditional}
              >
                <FormattedMessage id={"show_more"} />
              </PrimaryButton>
            </div>
          </>
        )}
      </div>

      <Modal
        show={selectedLaerAccess !== null}
        title={
          selectedLaerAccess !== null
            ? intl.formatMessage({ id: "customer_edit_laer_access_title" })
            : ""
        }
        size="xl"
        onClose={closeEditLaerAccessModal}
        footer={
          <>
            <LightButton onClick={closeEditLaerAccessModal}>
              <FormattedMessage id="cancel_button" />
            </LightButton>
            <PrimaryButton
              onClick={editLLMAction}
              disabled={accessCodes?.length > 1}
            >
              <FormattedMessage id="save_button" />
            </PrimaryButton>
          </>
        }
      >
        <div>
          {laerAceessLoading ? (
            <div className="align-content-center text-center">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">
                  <FormattedMessage id="loading" />
                </span>
              </div>
            </div>
          ) : (
            <>
              {laerAccessData ? (
                <>
                  <div className="w-100 d-flex flex-row gap-2">
                    <div className="w-100">
                      <label htmlFor="InputUnit" className="form-label">
                        <FormattedMessage id="field_unit" />
                      </label>
                      <input
                        id="InputUnit"
                        name="unit"
                        type="text"
                        className="form-control"
                        placeholder={intl.formatMessage({ id: "field_unit" })}
                        defaultValue={laerAccessData?.lockName}
                        disabled={true}
                      />
                    </div>

                    <div className="w-100">
                      <label htmlFor="InputSatel" className="form-label">
                        <FormattedMessage id="field_satel_code" />
                      </label>
                      <input
                        id="InputSatel"
                        name="satel"
                        type="text"
                        className="form-control"
                        placeholder={intl.formatMessage({
                          id: "field_satel_code",
                        })}
                        value={satelCode}
                        onChange={({ target: { value } }) =>
                          setSatelCode(value)
                        }
                      />
                    </div>
                  </div>

                  <div className="mt-4 w-100 d-flex flex-row justify-content-between align-items-center">
                    <span>
                      <FormattedMessage id="field_llm_code" />
                    </span>
                    {accessCodes?.length < 1 ? (
                      <PrimaryButton
                        className="fa fa-plus h-100"
                        onClick={addLaerAccessCode}
                      />
                    ) : null}
                  </div>

                  <div className="mt-2 d-flex flex-column gap-2">
                    {accessCodes?.map((accessCodeElement, index) => (
                      <div
                        key={`${index}-${accessCodeElement.code}`}
                        className="w-100 d-flex flex-row gap-2 align-items-center"
                      >
                        <input
                          name="accessCode"
                          type="text"
                          className="form-control"
                          placeholder={intl.formatMessage({
                            id: "field_llm_code",
                          })}
                          defaultValue={accessCodeElement?.code}
                          onBlur={(event) => {
                            changeLaerAccessCode(index, event.target.value);
                          }}
                        />
                        <DangerButton
                          style={{
                            margin: "0 !important",
                            padding: "0 !important",
                          }}
                          className="fa fa-trash h-100"
                          onClick={() => removeLaerAccessCode(index)}
                        />
                      </div>
                    ))}
                  </div>
                </>
              ) : (
                <FormattedMessage id="no_data" />
              )}
            </>
          )}
        </div>
      </Modal>

      <Modal
        show={selectedForChangeManualLockCustomer !== null}
        title={
          selectedForChangeManualLockCustomer !== null
            ? intl.formatMessage({ id: "customer_change_manual_lock_title" })
            : ""
        }
        size="xl"
        onClose={onCloseChangeManualLockModal}
        footer={
          <>
            <LightButton onClick={onCloseChangeManualLockModal}>
              <FormattedMessage id="cancel_button" />
            </LightButton>
            <DangerButton onClick={changeManualLockAction}>
              <FormattedMessage id="customer_change_manual_lock_button" />
            </DangerButton>
          </>
        }
      >
        <FormattedMessage id="customer_change_manual_lock_message" />
      </Modal>

      <Modal
        show={selectedForChangeCustomerParent !== null}
        title={
          selectedForChangeCustomerParent !== null
            ? intl.formatMessage({ id: "customer_change_parent_title" })
            : ""
        }
        size="xl"
        onClose={onCloseChangeParentModal}
        footer={
          <>
            <LightButton onClick={onCloseChangeParentModal}>
              <FormattedMessage id="cancel_button" />
            </LightButton>
            <PrimaryButton onClick={changeParentAction}>
              <FormattedMessage id="ok_button" />
            </PrimaryButton>
          </>
        }
      >
        <div className="w-100 d-flex flex-column gap-2">
          <div className="w-100">
            <input
              id="InputRadionMain"
              name="radio-user-type"
              type="radio"
              checked={selectedCustomerType === 0}
              onChange={(e) => setSelectedCustomerType(0)}
            />
            <label htmlFor="InputRadionMain" className="ml-10 form-label">
              <FormattedMessage id="customers_user_main" />
            </label>
          </div>

          <div className="w-100">
            <input
              id="InputRadionSub"
              name="radio-user-type"
              type="radio"
              checked={selectedCustomerType === 1}
              onChange={(e) => setSelectedCustomerType(1)}
            />
            <label htmlFor="InputRadionSub" className="ml-10 form-label">
              <FormattedMessage id="customers_user_sub_for" />
            </label>
          </div>

          <div className="w-100">
            <SelectSearch
              id="parentSearch"
              getOptions={getSearchParentOptions}
              onChange={(val, valOpt) => {
                setSelectedCustomerParentSearchValue(valOpt);
              }}
              value={selectedCustomerParentSearchValue}
              disabled={selectedCustomerType !== 1}
              placeholder={
                selectedCustomerParentSearchValue
                  ? selectedCustomerParentSearchValue.name
                  : intl.formatMessage({ id: "search_button" })
              }
              name="searchParent"
              search={true}
            />
          </div>

          {changeParentModalShowError ? (
            <div className="w-100 text-danger">
              <FormattedMessage id="customer_change_parent_error" />
            </div>
          ) : null}
        </div>
      </Modal>

      <Modal
        show={
          bankRatingModalRef !== null &&
          bankRatingUpdateConfirmationModalRef === null
        }
        title={
          bankRatingModalRef !== null
            ? intl.formatMessage({
              id: "customer_change_bank_rating_modal_title",
            })
            : ""
        }
        size="lg"
        onClose={closeBankRatingModal}
        footer={
          <>
            <LightButton onClick={closeBankRatingModal}>
              <FormattedMessage id="cancel_button" />
            </LightButton>
            <PrimaryButton
              onClick={() =>
                openBankRatingUpdateConfirmationModal(bankRatingModalRef)
              }
            >
              <FormattedMessage id="save_button" />
            </PrimaryButton>
          </>
        }
      >
        <div className="w-100">
          <label htmlFor="InputBankRating" className="form-label">
            <FormattedMessage id="field_bank_status" />
          </label>

          <DropdownList
            id="InputBankRating"
            name="bank-rating"
            data={[
              { value: 1, label: "01" },
              { value: 2, label: "02" },
              { value: 3, label: "03" },
              { value: 4, label: "04" },
              { value: 5, label: "05" },
              { value: 6, label: "06" },
            ]}
            value={editBankRating}
            onChange={(value) => setEditBankRating(value.value)}
            dataKey={"value"}
            textField={"label"}
            className={"cursor-pointer"}
            renderListItem={({ item }) => (
              <div
                className={
                  "px-2 py-2 llm-dropdown-option " +
                  getBankStatusColorClass(item.value)
                }
              >
                {item.label}
              </div>
            )}
          />
        </div>

        <div className="mt-4 w-100">
          <label className="form-label">
            <FormattedMessage id="bank_rating_history_list_title" />
          </label>

          {loadingBankRatingHistory ? (
            <div className="spinner-border" role="status">
              <span className="visually-hidden">
                <FormattedMessage id="loading" />
              </span>
            </div>
          ) : (
            <div className="w-100 container">
              {bankRatingHistory.length > 0 ? (
                bankRatingHistory.map((element: UserBankRating) => (
                  <div
                    className="row align-items-center my-2"
                    key={element.userId + element.updateDate.toISOString()}
                  >
                    <div className="col-md-1">
                      <div
                        style={{
                          height: "40px",
                          width: "40px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        className={
                          "border rounded text-white " +
                          getBankStatusColorClass(element.bankRating)
                        }
                      >
                        {element.bankRating >= 0
                          ? "0" + element.bankRating
                          : element.bankRating}
                      </div>
                    </div>
                    <div className="col-md-3">
                      {element.updateDate.toLocaleString("de", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                      })}
                    </div>
                    <div className="col-md-auto">
                      <a href={"mailto:" + element.performedUserEmail}>
                        {element.performedUserName}
                      </a>
                    </div>
                  </div>
                ))
              ) : (
                <span>
                  <FormattedMessage id="no_results" />
                </span>
              )}
            </div>
          )}
        </div>
      </Modal>

      <Modal
        show={bankRatingUpdateConfirmationModalRef !== null}
        title={
          bankRatingUpdateConfirmationModalRef !== null
            ? intl.formatMessage({
              id: "customer_change_bank_rating_confirmation_modal_title",
            })
            : ""
        }
        size="lg"
        onClose={closeBankRatingUpdateConfirmationModal}
        footer={
          <>
            <LightButton onClick={closeBankRatingUpdateConfirmationModal}>
              <FormattedMessage id="cancel_button" />
            </LightButton>
            <PrimaryButton onClick={updateBankRating}>
              <FormattedMessage id="save_button" />
            </PrimaryButton>
          </>
        }
      >
        <FormattedMessage id="customer_change_bank_rating_confirmation_modal_description" />
      </Modal>

      <Modal
        show={store365ModalRef !== null}
        title={"Store 365"}
        size="lg"
        onClose={closeStore365ModalRefModal}
        footer={
          <>
            <LightButton onClick={closeStore365ModalRefModal}>
              <FormattedMessage id="cancel_button" />
            </LightButton>
          </>
        }
      >
        <div className="table-content">
          <table className="table">
            <thead>
              <tr className="border-top-1 border-bottom-1">
                <th className="text-nowrap align-middle">
                  <FormattedMessage id={"field_file_name"} />
                </th>
                <th className="text-nowrap align-middle">
                  <FormattedMessage id={"field_file_date"} />
                </th>
                <th className="text-nowrap align-middle">
                  <FormattedMessage id={"field_file_status"} />
                </th>
                <th className="text-nowrap align-middle">
                  <FormattedMessage id={"field_file_error"} />
                </th>
              </tr>
            </thead>
            <tbody>
              {fileUploads.length ? (
                fileUploads.map((fileUpload, index) =>
                (
                  <tr
                    onClick={() => {
                      downloadFile(fileUpload.id, fileUpload.fileName);
                    }}
                    className="border-top-1 border-bottom-1  cursor-pointer hover-text-decoration-underline"
                  >
                    <td className="align-middle">
                      {fileUpload?.fileName || ""}
                    </td>
                    <td className="align-middle">
                      {fileUpload?.dateTime.toLocaleString() || ""}
                    </td>
                    <td className="align-middle">
                      {getTaskStatus(fileUpload)}
                    </td>
                    <td>{getErrorMessage(fileUpload)}</td>
                  </tr>
                )
                )
              ) : (
                <tr>
                  <td colSpan={11} className="text-center">
                    <FormattedMessage id={"no_results"} />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {isFileUploadLoading && (
          <div className="align-content-center text-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">
                <FormattedMessage id="loading" />
              </span>
            </div>
          </div>
        )}
        {showMoreFileUploads ? (
          <div className="text-center mt-40">
            <PrimaryButton
              onClick={() => {
                loadFileUploads();
              }}
              disabled={isFileUploadLoading}
            >
              <FormattedMessage id={"show_more"} />
            </PrimaryButton>
          </div>
        ) : (
          <></>
        )}
      </Modal>

      <Tooltip id="user-reset-password-tooltip" />
      <Tooltip id="user-has-access-info-tooltip" />
      <Tooltip id="job-update-tooltip" />
      <Tooltip id="job-reset-tooltip" />

      <ConfirmDialog 
        open={isResetPasswordDialgoVisible} 
        title={intl.formatMessage({ id: "reset_password_confirm_text" })}
        onNo={()=>{
          setIsResetPasswordDialgoVisible(false);
        }} 
        onYes={()=>{
          setLoaderVisible(true);
          setIsResetPasswordDialgoVisible(false);
          resetUserPasword(resetPasswordUserId).then(()=>{
            setIsSuccessAlertVisible(true);
            setTimeout(()=>{
              setIsSuccessAlertVisible(false);
            }, 2000);            
          }).catch(()=>{
            setIsErrorAlertVisible(true);
            setTimeout(()=>{
              setIsErrorAlertVisible(false);
            }, 2000);   
          }).finally(()=>{            
            setLoaderVisible(false);
          });
        }}/>

      <ConfirmDialog 
        open={isResetJobDialgoVisible} 
        title={intl.formatMessage({ id: "reset_job_confirm_text" })}
        onNo={()=>{
          setIsResetJobDialgoVisible(false);
        }} 
        onYes={()=>{
          setLoaderVisible(true);
          setIsResetJobDialgoVisible(false);
          resetLockJob(resetJobId).then(()=>{
            setIsSuccessAlertVisible(true);
            setTimeout(()=>{
              setIsSuccessAlertVisible(false);
            }, 2000);            
          }).catch(()=>{
            setIsErrorAlertVisible(true);
            setTimeout(()=>{
              setIsErrorAlertVisible(false);
            }, 2000);   
          }).finally(()=>{            
            setLoaderVisible(false);
          });
        }}/>
      
      
      <Backdrop
          sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
          open={loaderVisible}>
          <CircularProgress color="inherit" />
       </Backdrop>

    </AuthenticatedLayout>
  );

  function getTaskStatus(fileUpload) {
    switch (fileUpload.syncTaskSatus) {
      case 0:
        return <FormattedMessage id={"field_file_status_in_progress"} />;
      case 1:
        return <FormattedMessage id={"field_file_status_completed"} />;
      case 2:
        return <FormattedMessage id={"field_file_status_error"} />;
      default:
        <></>;
    }
  }

  function getErrorMessage(fileUpload) {
    const textLimit = 160;
    if (fileUpload.syncTaskSatus === 2) {
      if (fileUpload.errorMessage) {
        return (
          <>
            <span>
              {fileUpload.errorMessage.length > textLimit
                ? fileUpload.errorMessage.substring(0, textLimit - 3) + "..."
                : fileUpload.errorMessage}
            </span>
            <span title={intl.formatMessage({ id: "field_file_message_copy" })}>
              <BsCopy
                className="cursor-pointer ml-10 align-baseline"
                onClick={() => {
                  navigator.clipboard.writeText(fileUpload.errorMessage);
                  NotificationManager.info(
                    intl.formatMessage({ id: "field_file_message_copied" })
                  );
                }}
              />
            </span>
          </>
        );
      }
    }
    return <></>;
  }
}

export default List;

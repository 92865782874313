const ENGLISH_MESSAGES = {
    logout: 'Log out',

    search_placeholder: 'Type a Search Phrase',
    search_button: 'Search',

    filters_button: 'Filters',
    apply_filters: 'Apply filters',

    export_button: 'Export to file',

    loading: 'Loading...',
    no_results: 'Not found records',

    cancel_button: 'Cancel',
    ok_button: 'Ok',
    save_button: 'Save',
    delete_button: 'Delete',

    users: 'Users',
    users_add_button: 'Add user',
    user_add: 'User adding',
    user_edit: 'Editing the user "{name}"',
    user_delete: 'User deletion',

    user_delete_confirm_text: 'Do you really want to delete the user "{name}"?',

    locks: 'Locks',
    locks_add_button: 'Add lock',
    lock_add: 'Lock adding',
    lock_edit: 'Lock "{name}" editing',
    lock_delete: 'Lock deletion',
    lock_delete_confirm_text: 'Do you really want to delete the lock "{name}"?',

    lock_status_vacant: 'Vacant',
    lock_status_occupied: 'Occupied',
    lock_status_own_use: 'Own use',
    lock_status_maintenance: 'Maintenance',
    lock_status_out_of_order: 'Out of order',
    lock_status_locked: 'Locked',
    lock_status_deleted: 'Deleted',
    lock_status_disposed: 'Disposed',
    lock_status_reserved: 'Reserved',

    customers: 'Customers',
    customers_user_main: 'Main User',
    customers_user_sub: 'Additional User',
    customers_user_sub_for: 'Additional for',
    customer_change_manual_lock_title: 'Changing the status of manual locking',
    customer_change_parent_title: 'Changing the user type',
    customer_change_parent_error: 'It is not possible to add a user as a primary user',
    customer_change_manual_lock_message: 'Do you really want to change the manual lock status for this customer?',
    customer_change_manual_lock_button: 'Accept',

    customer_change_bank_rating_modal_title: 'Changing bank rating',
    customer_change_bank_rating_confirmation_modal_title: 'Changing bank rating confirmation',
    customer_change_bank_rating_confirmation_modal_description: 'Do you really want to change the bank rating for this user?',

    bank_rating_history_list_title: 'The history of changes in bank rating',
    bank_rating_performed_user: 'Performed user',

    field_api: 'API',
    field_email: 'E-Mail',
    field_password: 'Password',
    field_name: 'Title',
    field_description: 'Description',
    field_status: 'Status',
    field_first_name: 'First name',
    field_last_name: 'Last name',
    field_client_id: 'Client ID',
    field_actions: 'Actions',
    field_location: 'Location',
    field_unit: 'Unit',
    field_user: 'User',
    field_llm_code: 'LLM-Code',
    field_satel_code: 'Satel-Code',
    field_user_has_access: 'Has access',
    field_bank_status: 'Bank status',
    field_lock_status: 'Lock status',
    field_manual_lock: 'Manual lock',

    bool_true: 'Yes',
    bool_false: 'No',

    pagination_next: 'Next >>',
    pagination_prev: '<< Previous',
    show_more: 'Show more...',

    customer_edit_laer_access_title: 'Edit LLM-Codes',
    no_data: 'No data',

    field_file_date: 'File date',
    field_file_name: 'File name',
    field_file_status: 'Status',
    field_file_error: 'Error message',
    field_file_status_completed: 'Completed',
    field_file_status_error: 'Error',
    field_file_status_in_progress: 'In Progress',
    field_file_message_copy: 'Copy',
    field_file_message_copied: 'Message was copied',

    filter_button_show_empty: 'Empty Garages',
    filter_button_show_locked: 'Locked Garages',

    add_user_button: 'Add user',
    add_user_modal_title: 'Add user',

    field_phone: 'Phone',
    field_mobile: 'Mobile',
    field_city: 'City',
    field_address: 'Address',
    field_postal_code: 'Postal Code',
    button_reset:'Reset',
    last_update_date:'Last update date',
    last_success_update_date:'Last success update date',

    reset_password_confirm_text: 'Do you really want to reset user\'s password?',
    reset_job_confirm_text: 'Do you really want to reset job?',
};

export default ENGLISH_MESSAGES;
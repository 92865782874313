const RUSSIAN_MESSAGES = {
    logout: 'Выход',

    search_placeholder: 'Введите фразу для поиска',
    search_button: 'Поиск',

    filters_button: 'Фильтры',
    apply_filters: 'Применить фильтры',

    loading: 'Загрузка...',
    no_results: 'Ничего не найдено',

    export_button: 'Экспортировать в файл',

    cancel_button: 'Отмена',
    ok_button: 'Ок',
    save_button: 'Сохранить',
    delete_button: 'Удалить',

    add_button: 'Добавить',

    users: 'Пользователи',
    users_add_button: 'Добавить пользователя',
    user_show: 'Гаражи пользователя "{last_name} {first_name}"',
    user_add: 'Добавление пользователя',
    user_edit: 'Редактирование пользователя "{name}"',
    user_delete: 'Удаление пользователя',
    user_delete_confirm_text: 'Вы действительно хотите удалить пользователя "{name}"?',

    locks: 'Гаражи',
    locks_add_button: 'Добавить гараж',
    lock_add: 'Добавление гаража',
    lock_edit: 'Редактирование гаража "{name}"',
    lock_delete: 'Удаление гаража',
    lock_delete_confirm_text: 'Вы действительно хотите удалить гараж "{name}"?',

    lock_status_vacant: 'Вакантный',
    lock_status_occupied: 'Занятый',
    lock_status_own_use: 'Собственное использование',
    lock_status_maintenance: 'Поддержка',
    lock_status_out_of_order: 'Вышел из строя',
    lock_status_locked: 'Заперт',
    lock_status_deleted: 'Удален',
    lock_status_disposed: 'Расположен',
    lock_status_reserved: 'Зарезервирован',

    customers: 'Клиенты',
    customers_user_main: 'Основной',
    customers_user_sub: 'Дополнительный',
    customers_user_sub_for: 'Дополнительный к',
    customer_change_manual_lock_title: 'Изменение статуса ручной блокировки',
    customer_change_parent_title: 'Изменение типа пользователя',
    customer_change_parent_error: 'Невозможно добавить пользователя как основного',
    customer_change_manual_lock_message: 'Вы действительно хотите изменить статус ручной блокировки для данного клиента?',
    customer_change_manual_lock_button: 'Подтвердить',

    customer_change_bank_rating_modal_title: 'Изменение банковского рэйтинга',
    customer_change_bank_rating_confirmation_modal_title: 'Подтверждение изменения банковского рэйтинга',
    customer_change_bank_rating_confirmation_modal_description: 'Вы действительно хотите изменить банковский рэйтинг для этого пользователя?',

    bank_rating_history_list_title: 'История изменеия банковского рэйтинга',
    bank_rating_performed_user: 'Выполнен пользователем',

    field_api: 'API',
    field_email: 'E-Mail',
    field_password: 'Пароль',
    field_name: 'Название',
    field_description: 'Описание',
    field_status: 'Статус',
    field_first_name: 'Имя',
    field_last_name: 'Фамилия',
    field_client_id: 'Идентификатор клиента',
    field_actions: 'Действия',
    field_location: 'Местоположение',
    field_unit: 'Юнит',
    field_user: 'Пользователь',
    field_llm_code: 'LLM-Код',
    field_satel_code: 'Satel-Код',
    field_user_has_access: 'Наличие доступа',
    field_bank_status: 'Банковский статус',
    field_lock_status: 'Статус замка',
    field_manual_lock: 'Ручная блокировка',

    bool_true: 'Да',
    bool_false: 'Нет',

    pagination_next: 'Вперёд >>',
    pagination_prev: '<< Назад',
    show_more: 'Показать больше...',

    customer_edit_laer_access_title: 'Редактирование LLM-Кодов',
    no_data: 'Нет данных',

    field_file_date: 'Дата файла',
    field_file_name: 'Имя файла',
    field_file_status: 'Статус',
    field_file_error: 'Текст ошибки',
    field_file_status_completed: 'Завершено',
    field_file_status_error: 'Ошибка',
    field_file_status_in_progress: 'В процессе',
    field_file_message_copy: 'Скопировать',
    field_file_message_copied: 'Сообщение скопировано',

    
    filter_button_show_empty: 'Пустые гаражи',
    filter_button_show_locked: 'Заблокированные гаражи',

    add_user_button: 'Добавить пользователя',
    add_user_modal_title: 'Добавить пользователя',

    field_phone: 'Телефон',
    field_mobile: 'Сотовый',
    field_city: 'Город',
    field_address: 'Адрес',
    field_postal_code: 'Индекс',
    button_reset:'Сбросить',
    last_update_date:'Дата последнего обновления',
    last_success_update_date:'Дата последнего успешного обновления',

    reset_password_confirm_text: 'Вы действительно хотите сбросить пароль пользователя?',
    reset_job_confirm_text: 'Вы действительно хотите сбросить задачу?',
};

export default RUSSIAN_MESSAGES;